<template>
    <div class="flex items-center justify-between">
    <p class="uppercase font-semibold mb-2 text-gray-700 mt-8"> Performance 
            <span class="text-sm normal-case font-normal">(Filter values to see some solutions)</span></p>
            <div class="flex items-center rounded-full p-2 bg-moss shadow-md cursor-pointer">
                <FunnelIcon @click="showBoxes = !showBoxes" class="icon thick icon-small white" />
            </div>
        </div>
    <div v-if="showBoxes" class="flex items-start">
        <!-- OTR & WVTR -->
        <div class="w-3/5 filter-box shadow-md rounded-lg mb-6 bg-acqua mr-6">
            <div class="filter-title flex justify-between px-4 py-3">
                <div>
                    <p class="title">Barrier Properties</p>
                    <p class="subtitle">Performance evaluation</p>
                </div>
                <Popover class="relative">
                    <PopoverButton class="flex items-center">
                        <EllipsisHorizontalIcon class="icon icon-small white" />
                    </PopoverButton>
                    <PopoverPanel class="absolute z-10 bg-moss rounded top-6 right-0 min-w-32 text-white">
                        <div class="grid grid-cols-1 divide-y">
                            <div @click="showBarrierFilters = !showBarrierFilters"
                                class="py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center">
                                <FunnelIcon class="icon icon-x-small mr-4 white thick-2" />
                                <p>Filter</p>
                            </div>
                            <div @click="handleShowOTRChart"
                                class="py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center">
                                <ChartBarIcon class="icon icon-x-small mr-4 no-stroke" />
                                <p>Perform</p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Popover>
            </div>
            <div class="filter-numbers flex cursor-pointer">
                <p class="w-full text-center font-normal leading-6"> 
                    <span class="otr">OTR</span> 446 <span class="text-white block text-xs ">Measurements</span>
                </p>
                <Transition>
                    <p v-if="briefData.id == 1 && selectedOtr && selectedOtrHum && selectedOtrTemp" class="w-1/3 text-center font-normal leading-6"> 28 <span class="text-white block text-xs ">Measurements</span></p>
                </Transition>
                <Transition>
                    <p  v-if="briefData.id == 2 && selectedOtr && selectedOtrHum && selectedOtrTemp" class="w-1/3 text-center font-normal leading-6"> 17 <span class="text-white block text-xs ">Measurements</span></p>
                </Transition>

                <p class="w-full text-center font-normal leading-6"><span class="otr">WVTR</span> 355 <span
                        class="text-white block text-xs ">Measurements</span>
                </p>
                <Transition>
                    <p  v-if="briefData.id == 1 && selectedWvtr && selectedWvtrHum && selectedWvtrTemp" class="w-1/3 text-center font-normal leading-6"> 26 <span class="text-white block text-xs ">Measurements</span></p>
                </Transition>
                <Transition>
                    <p  v-if="briefData.id == 2 && selectedWvtr && selectedWvtrHum && selectedWvtrTemp" class="w-1/3 text-center font-normal leading-6"> 11 <span class="text-white block text-xs ">Measurements</span></p>
                </Transition>
            </div>
            <div v-if="showBarrierFilters" class="filter-options">
                <p class="border-t uppercase font-medium px-4 py-1 text-white text-center text-sm">testing conditions
                </p>
                <div class="flex">
                    <!-- OTR FILTER OPTIONS -->
                    <div class="filter-options w-1/2 flex flex-col px-3 py-3 text-sm border-r border-white">
                        <p class="uppercase font-medium">Temperature</p>
                        <div class="mt-1 mb-3 flex">
                            <div v-for="temp in tempOptions" :key="temp.value" class="filter-option flex items-center">
                                <input type="radio" :value="temp.value" v-model="selectedOtrTemp">
                                <label>{{ temp.label }} </label>
                            </div>
                        </div>
                        <p class="uppercase font-medium">Relative humidity</p>
                        <div class="mb-3 flex">
                            <div v-for="humidity in humOptionsOtr" :key="humidity.value"
                                class="filter-option flex items-center mt-1">
                                <input type="radio" :value="humidity.value" v-model="selectedOtrHum">
                                <label>{{ humidity.label }} </label>
                            </div>
                        </div>
                        <p class="uppercase font-medium">OTR</p>
                        <div class="mt-1 flex">
                            <div v-for="otr in otrOptions" :key="otr.value" class="filter-option flex items-center">
                                <input type="radio" :value="otr.value" v-model="selectedOtr">
                                <label>{{ otr.label }} </label>
                            </div>
                        </div>
                    </div>
                    <!-- WVTR FILTER OPTIONS -->
                    <div class="filter-options w-1/2 flex flex-col px-3 py-3 text-sm relative">
                        <p class="uppercase font-medium">Temperature</p>
                        <div class="mb-3 mt-1 flex">
                            <div v-for="temp in tempOptions" :key="temp.value" class="filter-option flex items-center">
                                <input type="radio" :value="temp.value" v-model="selectedWvtrTemp">
                                <label>{{ temp.label }} </label>
                            </div>
                        </div>
                        <p class="uppercase font-medium">Relative humidity</p>
                        <div class="mb-3 mt-1 flex">
                            <div v-for="kit in humOptions" :key="kit.value" class="filter-option flex items-center">
                                <input type="radio" :value="kit.value" v-model="selectedWvtrHum">
                                <label>{{ kit.label }} </label>
                            </div>
                        </div>
                        <p class="uppercase font-medium">WVTR</p>
                        <div class="mt-1 flex">
                            <div v-for="wvtr in wvtrOptions" :key="wvtr.value" class="filter-option flex items-center">
                                <input type="radio" :value="wvtr.value" v-model="selectedWvtr">
                                <label>{{ wvtr.label }} </label>
                            </div>
                        </div>
                        <div @click="hanldeShowOTRFilteredChart" v-if="showOTRPlayButton" class="chart-icon">
                            <ChartBarIcon class="icon icon-small" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- HEAT SEALABILITY -->
        <div class="w-2/5 filter-box shadow-md rounded-lg mb-6 bg-peach">
            <div class="filter-title flex justify-between px-4 py-3">
                <div>
                    <p class="title">Heat Sealability</p>
                    <p class="subtitle">Performance evaluation</p>
                </div>
                <Popover class="relative">
                    <PopoverButton class="flex items-center">
                        <EllipsisHorizontalIcon class="icon icon-small white" />
                    </PopoverButton>
                    <PopoverPanel class="absolute z-10 bg-peach-strong rounded top-6 right-0 min-w-32 text-white">
                        <div class="grid grid-cols-1 divide-y">
                            <div @click="showHSFilters = !showHSFilters"
                                class="py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center">
                                <FunnelIcon class="icon icon-x-small mr-4 white thick-2" />
                                <p>Filter</p>
                            </div>
                            <div @click="handleShowHShart"
                                class="py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center">
                                <ChartBarIcon class="icon icon-x-small mr-4 no-stroke" />
                                <p>Perform</p>
                            </div>
                            <div @click="hanldeshowHeatMap"
                                class="py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center">
                                <ChartBarSquareIcon class="icon icon-x-small mr-4 white thick-2" />
                                <p>Explore</p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Popover>
            </div>
            <div class="filter-numbers flex cursor-pointer">
                <p class="w-full text-center font-normal border-0 leading-6">1415
                    <span class="text-white block text-xs ">Measurements</span>
                </p>
                <Transition>
                    <p v-if="briefData.id == 1 && hsTime.length > 0 && hsTemp.length > 0 && hsPress.length > 0" class="w-1/3 text-center font-normal leading-6"> 7 <span class="text-white block text-xs ">Measurements</span></p>
                </Transition>
                <Transition>
                    <p  v-if="briefData.id == 2 && hsTime.length > 0 && hsTemp.length > 0 && hsPress.length > 0" class="w-1/3 text-center font-normal leading-6"> 7 <span class="text-white block text-xs ">Measurements</span></p>
                </Transition>
            </div>
            <!-- HS FILTER OPTIONS -->
            <div v-if="showHSFilters" class="filter-options flex-col text-sm">
                <p class="uppercase font-medium px-4 py-1 text-white text-center text-sm">testing conditions</p>
                <div class="filter-options flex flex-col px-3 py-3 text-sm relative">
                    <p class="uppercase font-medium">Time</p>
                    <div class="mb-3 flex">
                        <div v-for="time in timeOptions" :key="time.value" class="filter-option flex items-center mt-1">
                            <input type="checkbox" :value="time.value" v-model="hsTime">
                            <label>{{ time.label }} </label>
                        </div>
                    </div>
                    <p class="uppercase font-medium">Pressure</p>
                    <div class="mt-1 mb-3 flex flex-wrap">
                        <div v-for="pressure in pressureOptions" :key="pressure.value"
                            class="filter-option flex items-center">
                            <input type="checkbox" :value="pressure.value" v-model="hsPress">
                            <label>{{ pressure.label }} </label>
                        </div>
                    </div>
                    <p class="uppercase font-medium">Temperature</p>
                    <div class="mt-1 flex">
                        <div v-for="temp in tempHSOptions" :key="temp.value" class="filter-option flex items-center">
                            <input type="checkbox" :value="temp.value" v-model="hsTemp">
                            <label>{{ temp.label }} </label>
                        </div>
                    </div>
                    <div @click="handleShowHSFilteredChart" v-if="showHSPlayButton" class="chart-icon">
                        <ChartBarIcon class="icon icon-small" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-2 gap-4">
        <!-- OTR plots -->
        <div v-if="creatingOTRPlot || showOTRChart || showOTRFilteredChart"
            class="bg-white shadow-md border p-2 mb-4 rounded-lg relative col-span-1 order-1 mr-5 min-h-96">
            <template v-if="creatingOTRPlot">
                <ChartBarIcon class="animate-pulse spin-chart" />
            </template>
            <template v-else-if="showOTRChart">
                <XCircleIcon @click="showOTRChart = false"
                    class="icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300"
                    :class="{ 'opacity-0': !showOTRChart, 'opacity-100': showOTRChart }" />
                <ChartOTR class="transition-opacity duration-1000 delay-300"
                    :class="{ 'opacity-0': !showOTRChart, 'opacity-100': showOTRChart }" />
            </template>
            <template v-else-if="showOTRFilteredChart">
                <XCircleIcon @click="showOTRFilteredChart = false"
                    class="icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300"
                    :class="{ 'opacity-0': !showOTRFilteredChart, 'opacity-100': showOTRFilteredChart }" />
                <template v-if="briefData.id == 2">
                    <ChartOTRFiltered class="transition-opacity duration-1000 delay-300"
                    :class="{ 'opacity-0': !showOTRFilteredChart, 'opacity-100': showOTRFilteredChart }" />
                </template>
                <template v-if="briefData.id == 1">
                    <ChartOTRFilteredPet class="transition-opacity duration-1000 delay-300"
                    :class="{ 'opacity-0': !showOTRFilteredChart, 'opacity-100': showOTRFilteredChart }" />
                </template>
            </template>
        </div>
        <!-- HS HEAT MAP -->
        <div v-if="creatingHeatMap || showHeatMap"
            class="bg-white shadow-md border p-2 mb-4 rounded-lg relative min-h-96 col-span-1 order-2">
            <template v-if="creatingHeatMap">
                <ChartBarIcon class="animate-pulse spin-chart hs" />
            </template>
            <template v-else-if="showHeatMap">
                <XCircleIcon @click="showHeatMap = false"
                    class="icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300"
                    :class="{ 'opacity-0': !showHeatMap, 'opacity-100': showHeatMap }" />
                <ChartHeatMap class="transition-opacity duration-1000 delay-300"
                    :class="{ 'opacity-0': !showHeatMap, 'opacity-100': showHeatMap }" />
            </template>
        </div>
        <!-- HS plots -->
        <div v-if="creatingHSPlot || showHsChart || showHsFilteredChart"
            class="bg-white shadow-md border p-2 mb-4 rounded-lg relative min-h-96 col-span-1 order-2">
            <template v-if="creatingHSPlot">
                <ChartBarIcon class="animate-pulse spin-chart hs" />
            </template>
            <template v-else-if="showHsChart">
                <XCircleIcon @click="showHsChart = false"
                    class="icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300"
                    :class="{ 'opacity-0': !showHsChart, 'opacity-100': showHsChart }" />
                <ChartHS class="transition-opacity duration-1000 delay-300"
                    :class="{ 'opacity-0': !showHsChart, 'opacity-100': showHsChart }" />
            </template>
            <template v-else-if="showHsFilteredChart">
                <XCircleIcon @click="showHsFilteredChart = false"
                    class="icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300"
                    :class="{ 'opacity-0': !showHsFilteredChart, 'opacity-100': showHsFilteredChart }" />
                <template v-if="briefData.id == 2">
                    <ChartHSFiltered class="transition-opacity duration-1000 delay-300" :class="{ 'opacity-0': !showHsFilteredChart, 'opacity-100': showHsFilteredChart}" />
                </template>
                <template v-if="briefData.id == 1">
                    <ChartHSFilteredPet class="transition-opacity duration-1000 delay-300" :class="{ 'opacity-0': !showHsFilteredChart, 'opacity-100': showHsFilteredChart && briefData.id == 1 }" />
                </template>
            </template>
        </div>
    </div>

    <!-- CREATE SOLUTION BUTTON -->
    <template v-if="showHsFilteredChart && showOTRFilteredChart && !showDesigns">
        <div @click="handleShowDesign"
            class="fixed flex items-center rounded-full px-4 py-2 bg-moss shadow-md bottom-7 right-8 transition-opacity duration-1000 delay-1000 cursor-pointer"
            :class="{ 'opacity-0': !showHsFilteredChart || !showHsFilteredChart, 'opacity-100': showHsFilteredChart && showOTRFilteredChart }">
            <LayerIcon class="icon icon-m white mr-2" />
            <p class="text-white text-xs uppercase">Show solution</p>
        </div>
    </template>

    <!-- DESIGNS -->
    <template v-if="showDesigns">
        <div class="transition-opacity duration-1000 delay-1000 cursor-pointer"
            :class="[showDesigns ? 'opacity-100' : 'opacity-0']">
            <DesingsCards :solutions="briefData.solutions" />
        </div>
    </template>

</template>
<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import { EllipsisHorizontalIcon, ChartBarIcon } from '@heroicons/vue/20/solid';
import { FunnelIcon, ChartBarSquareIcon } from '@heroicons/vue/24/outline';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import ChartHS from './ChartHS.vue';
import ChartHSFiltered from './ChartHSFiltered.vue';
import ChartHSFilteredPet from './ChartHSFilteredPet.vue';
import ChartOTR from './ChartOTR.vue';
import ChartOTRFiltered from './ChartOTRFiltered.vue';
import ChartOTRFilteredPet from './ChartOTRFilteredPet.vue';
import ChartHeatMap from './ChartHeatMap.vue';
import DesingsCards from './DesingsCards.vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import LayerIcon from '@/components/LayerIcon.vue';

defineProps(['briefData']);

const showDesigns = ref(false);
const showBoxes = ref(false);

const showHsChart = ref(false);
const showHsFilteredChart = ref(false);
const showOTRChart = ref(false);
const showOTRFilteredChart = ref(false);
const creatingOTRPlot = ref(false);
const creatingHSPlot = ref(false);
const showHeatMap = ref(false);
const creatingHeatMap = ref(false);

const showBarrierFilters = ref(false);
const showHSFilters = ref(false);
const showOTRPlayButton = computed<boolean>(() => !!selectedOtr.value && !!selectedOtrHum.value && !!selectedOtrTemp.value && !!selectedWvtr.value && !!selectedWvtrHum.value && !!selectedWvtrTemp.value)
const showHSPlayButton = computed<boolean>(() => hsPress.value.length > 0 && hsTemp.value.length > 0 && hsTime.value.length > 0);

const hanldeshowHeatMap = () => {
    creatingHeatMap.value = true;
    setTimeout(() => {
        showHeatMap.value = true;
        creatingHeatMap.value = false
    }, 1300)
}


const handleShowHShart = () => {
    creatingHSPlot.value = true;
    setTimeout(() => {
        showHsChart.value = true;
        creatingHSPlot.value = false
    }, 900)
}

const handleShowHSFilteredChart = () => {
    creatingHSPlot.value = true;
    showHsChart.value = false;
    showHSFilters.value = false;
    setTimeout(() => {
        showHsFilteredChart.value = true;
        creatingHSPlot.value = false
    }, 1500)
}

const hanldeShowOTRFilteredChart = () => {
    creatingOTRPlot.value = true;
    showOTRChart.value = false;
    showBarrierFilters.value = false;
    setTimeout(() => {
        showOTRFilteredChart.value = true;
        creatingOTRPlot.value = false
    }, 1500)
}

const handleShowOTRChart = () => {
    creatingOTRPlot.value = true;
    setTimeout(() => {
        showOTRChart.value = true;
        creatingOTRPlot.value = false
    }, 1000)
}

const handleShowDesign = () => {
    showBarrierFilters.value = false;
    showHSFilters.value = false;
    setTimeout(() => {
        showDesigns.value = true;
    }, 1200)

}

const tempOptions = [{ label: '23°C', value: 1 }, { label: '38°C', value: 2 }]
const selectedOtrTemp = ref(null);
const selectedWvtrTemp = ref(null)

const humOptions = [{ label: '50%', value: 2 }, { label: '90%', value: 3 }]
const humOptionsOtr = [{ label: '0%', value: 1 }, { label: '50%', value: 2 }, { label: '90%', value: 3 }]
const selectedOtrHum = ref(null);
const selectedWvtrHum = ref(null);

const otrOptions = [{ label: '<= 1', value: 2 }, { label: '<= 10', value: 3 }, { label: '<= 30', value: 6 }, { label: '<= 100', value: 15 }]
const wvtrOptions = [{ label: '<= 1', value: 3 }, { label: '<= 10', value: 20 }, { label: '<= 30', value: 6 }, { label: '<= 100', value: 15 }]
const selectedOtr = ref(null);
const selectedWvtr = ref(null);

const timeOptions = [{ label: '0.2s', value: 1 }, { label: '0.5s', value: 2 }, { label: '1s', value: 3 }, { label: '1.5s', value: 4 }];
const pressureOptions = [{ label: '150Kpa', value: 1 }, { label: '300Kpa', value: 2 }, { label: '350Kpa', value: 3 }, { label: '400Kpa', value: 4 }, { label: '450Kpa', value: 5 }, { label: '500Kpa', value: 6 }];
const tempHSOptions = [{ label: '130°C', value: 1 }, { label: '140°C', value: 2 }, { label: '150°C', value: 3 }, { label: '160°C', value: 4 }, { label: '170°C', value: 5 }, { label: '180°C', value: 6 }]

const hsTime = ref([]);
const hsTemp = ref([]);
const hsPress = ref([]);
</script>
<style lang="scss" scoped>
.filter-box {
    color: $moss;

    .filter-title {
        line-height: 1.2;

        .title {
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 0;
        }

        .subtitle {
            font-size: 0.8rem;
            font-weight: 300;
        }
    }

    .filter-numbers {
        border-top: 1px solid white;

        p:last-child {
            border: none;
        }

        p {
            position: relative;
            padding: 0.8rem 0.5rem;
            font-size: 1.2rem;
            border-right: 1px solid white;

            span.otr {
                position: absolute;
                top: 50%;
                left: 16px;
                transform: translate(0%, -50%);
                font-weight: 400;
                color: rgba(255, 255, 255, 0.5);
                font-size: 1.7rem;

                &.final {
                    position: relative;
                    top: auto;
                    left: auto;
                    transform: none;
                }
            }
        }
    }

    .filter-options {
        border-top: 1px solid white;

        .filter-option {
            margin-bottom: 0.5rem;
            font-size: 1rem;

            input {
                width: 14px;
                height: 14px;
                border-radius: 5px;
                margin-right: 0.3rem;

                &:checked {
                    background-image: url("@/assets/icons/black-check-box.svg");
                }
            }

            label {
                display: inline-flex;
                font-size: 0.85rem;
                cursor: pointer;
                margin-right: 0.8rem;
            }
        }
    }

    .no-stroke {
        stroke: none;
    }

    .chart-icon {
        border-radius: 50%;
        border: 2px solid white;
        position: absolute;
        padding: 6px;
        top: -30px;
        right: 17px;

        svg {
            stroke: none;
            color: white;
        }

    }
}
.spin-chart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke: none;
    color: $acqua;
    color: #e9f1eb;
    width: 6rem;
    height: 6rem;

    &.hs {
        color: $peach-light;
    }
    &.heat {
        color: $nude;
    }
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
  transition-delay: 800ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>