<template>
    <div>
        <div class="edit-tds select-none" @click="openModal" style="right:120px" title="Technical Datasheet Extraction" :class="{active: isOpen}">
            <TableCellsIcon class="icon" />
            <p>TDS</p>
        </div>
        <ModalSlots :show="isOpen" dialogClass="extract-tds-modal" @close-modal="closeModal">
            <template v-slot:header><p>Upload or select Technical Datasheet for Extraction</p></template>
            <template v-slot:body>
                <LoaderPage v-if="extractInProgress" loader-text="Submitting Form" gif-position="center"/>
                <div class="form-container m-4 mt-0"> 
                    <FormErrorToast :is-showing="!!errorsDuringMutation" title="Warning!" :subtitle="errorsDuringMutation" toast-type="warning" /> 
                    <FormErrorToast :is-showing="formSubmited && !formIsValid" title="The following fields are mandatory:" :subtitle="errorFields.join(', ')" /> 
                    <form class="add-asset-form mt-4" >
                        <div class="grid grid-cols-3 gap-y-2.5 gap-x-4">
                            <RadioGroup
                                class="col-span-3 mb-0 select-none"
                                v-model="vModels['isNewUpload'].value"
                                :class="{ 'error-form-field': errorFields.includes('isNewUpload') && formSubmited }"
                                :options="newUpload"
                                :label="'Do you want to select an already uploaded TDS file or upload a new TDS?'"
                                />
                            <div class="col-span-3" v-if="!vModels['isNewUpload'].value"  :class="{ 'error-form-field': errorFields.includes('assetFile') && formSubmited }">
                                <label>Select Technical Datasheet Pdf File *</label>
                                <select class="w-full" v-model="vModels['assetFile'].value">
                                    <option selected :value=null></option>
                                    <option v-for="status in assetPdfFiles" :value="Number(status.id)"  :key="status.id">{{status.filename}}</option>
                                </select>
                            </div>
                            
                            <div class="col-span-3 mt-2" v-else :class="{ 'error-form-field': errorFields.includes('file') && formSubmited }">
                                <AssetTdsFileUploadField/>
                            </div>
                        </div>
                    </form>
                </div>
            </template>
            <template v-slot:footer>
                <button class="button cancel" @click="closeModal">cancel</button>
                <button class="button green-accept" @click="handleExtract">Extract</button>
            </template>
        </ModalSlots>
    </div>
</template>
<script setup lang="ts">
import { defineProps,ref, computed } from 'vue';
import { TableCellsIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import ModalSlots from "@/components/ModalSlots.vue";
import AssetTdsFileUploadField from './AssetTdsFileUploadField.vue';
import {ASSET_TDS_FIELDS} from '@/models/assetTdsFormFields';
import FormErrorToast from '@/components/FormErrorToast.vue';
import LoaderPage from "./LoaderPage.vue";
import { useQuery } from '@vue/apollo-composable';
import {TDS_QUERY} from "@/grapql/tdsQuery";
import RadioGroup from "@/components/shared/RadioGroup.vue";

const props = defineProps(["assetPdfFiles"]);

const store = useStore();

const assetId = computed(()=>store.state.assetTdsModule.asset );
const pollingInProgress = computed(()=>store.state.assetTdsModule.polling);

const isOpen = ref(false);
const closeModal = () => { isOpen.value = false;}
const openModal = () => {
    if(!pollingInProgress.value){
        isOpen.value = true; 
    }
}

const newUpload = [
  { key: false, value: "I want to select from list" },
  { key: true, value: "I want to upload new tds"},
];

const errorsDuringMutation = computed(() => store.state.assetTdsModule.extractError);
const extractInProgress = computed(()=> store.state.assetTdsModule.extractInProgress);


// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => ASSET_TDS_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: ASSET_TDS_FIELDS.graphqlValue,
    mutation: 'assetTdsModule/setSingleValue'
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.assetTdsModule[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
            }
        })
    })
    return object;
}
const vModels = formFields(formFieldOptions);

const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.assetTdsModule[ASSET_TDS_FIELDS.graphqlValue]);
const formIsValid = computed(() => ASSET_TDS_FIELDS.mandatoryFormFields.every(mf => {
    if(mf=="assetFile" &&  !!valuesSubmitted.value['isNewUpload']==true){
        return true;
    }
    else if (mf=='file' && !!valuesSubmitted.value['isNewUpload']==false){
        return true;
    }
    else if(mf=="isNewUpload" && (valuesSubmitted.value['isNewUpload']==false || valuesSubmitted.value['isNewUpload']==true)){
        return true;
    }
    return !!valuesSubmitted.value[mf]
}));
const errorFields = computed(() => ASSET_TDS_FIELDS.mandatoryFormFields.filter(mf => {
    if(mf=="assetFile"){
        return !!valuesSubmitted.value['isNewUpload']==true ? false: !valuesSubmitted.value[mf];
    }
    else if (mf=='file'){
        return !!valuesSubmitted.value['isNewUpload']==true ? !valuesSubmitted.value[mf]: false;
    }
    else if(mf=="isNewUpload"){
        return valuesSubmitted.value['isNewUpload']==true || valuesSubmitted.value['isNewUpload']==false ? false : true;
    }
    return !valuesSubmitted.value[mf]
}) );


const handleExtract = async () =>{
    formSubmited.value = true;
    if (formIsValid.value) {
        const query = ASSET_TDS_FIELDS.query;
        await store.dispatch(query)
        .then(()=>{
            formSubmited.value = false;
            closeModal();
            store.commit("assetTdsModule/resetStore");

            // start polling with 1 second interval until you get result,
            getTdsandParseByPolling();
        })
        .catch((error)=>{
            console.log('Catched Error: '+error)
        });
}

}


const getTdsandParseByPolling= () =>{
    // set polling  is progress to true
    store.commit('assetTdsModule/setPolling', true);
    const {onResult, onError, stop} = useQuery(TDS_QUERY, {id: assetId.value},{pollInterval:3500, fetchPolicy: 'no-cache'});
    onError((error)=>{
        console.log(error);
        stop();
        // set polling  is progress to false
        store.commit('assetTdsModule/setPolling', false);
    })
    onResult(async queryResult => {
        if (queryResult.data && queryResult?.data?.assetTempTdses) {
            // if there is data, so stop polling
            if(queryResult.data.assetTempTdses.edges.length > 0){
                stop();
                // set polling  is progress to to false
                store.commit('assetTdsModule/setPolling', false);
                store.commit('assetTdsModule/setTempTdsesValue', queryResult.data.assetTempTdses.edges.map(a=>a.node));
                
            }
        }
    })
}

</script>