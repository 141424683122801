<template>
    <SingleFileUpload  label-text="File" :preselected-file="fileAlreadySelected" @file-to-upload="onFileToUpload" accept-extensions=".pdf" accept-text="PDF"/>
</template>

<script setup lang="ts">
import SingleFileUpload from '@/components/shared/SingleFileUpload.vue';
import { onBeforeMount, ref} from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const fileAlreadySelected = ref(null)

const onFileToUpload = (file:File) => {
    store.commit('assetTdsModule/setFileToUpload', file);
}

onBeforeMount(() => {
    const storeSavedFile = store.getters['assetTdsModule/getFile'];
    if ( storeSavedFile ) {
        fileAlreadySelected.value = storeSavedFile
    }
})


</script>