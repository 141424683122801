<template>
    <div v-if="assetDetailMainData" class="asset-detail-container" :class="{'pannelClose': !assessmentPannelOpen}">
        <AssetTdsProcessModal :asset-id="assetID" @on-processed="handleTdsOnProcessed" />
        <div class="assessment-content">
            <div class="assessment-close" v-if="!assessmentPannelOpen" @click="togglePannel">
                <ChevronDoubleRightIcon class="icon icon-small internal-pannel" />
                <p>Assessment</p>
            </div>
            <AssetDetailAssessmentSection v-else :assetDetailAssessmentData="assetDetailAssessmentData"/>
        </div>
        <div class="main-content">
            <AssetDetailMainSection :assetDetailMainData="assetDetailMainData"  />
        </div>
    </div>
    <div v-else class="loading-gif"></div>
</template>
<script setup lang="ts">
    import { computed, defineProps, ref } from 'vue';
    import { useQuery } from '@vue/apollo-composable';
    import { useRoute } from 'vue-router';
    import { PATENT_QUERY } from '@/grapql/patentQuery';
    import { RESEARCH_PAPER_QUERY } from '@/grapql/researchPaperQuery';
    import { ASSET_TYPES } from '@/models/technologyAssetConstants';
    import { RESEARCH_PROJECT_QUERY } from '@/grapql/researchProjectQuery';
    import { OTHER_TECHNOLOGY_QUERY } from '@/grapql/otherTechnologyQuery';
    import { useStore } from 'vuex';
    import { useAssetDataDetailToMainContent } from '@/composables/useAssetDataDetailToMainContent';
    import { useAssetDataDetailToAssessmentContent } from '@/composables/useAssetDataDetailToAssessmentContent';
    import AssetDetailMainSection from '@/components/AssetDetailMainSection.vue';
    import AssetDetailAssessmentSection from '@/components/AssetDetailAssessmentSection.vue';
    import { ChevronDoubleRightIcon } from '@heroicons/vue/24/solid';
    import { AssetDetailMainDataType } from '@/models/technologyAssetInterface';
    import AssetTdsProcessModal from '@/components/AssetTdsProcessModal.vue';


    const route = useRoute();
    const store = useStore();
    const props = defineProps({id: { type: String }});
    const assetType = computed(() => props?.id.split('-').shift() ?? route.params.id.toString().split('-').shift());
    const assetID = computed(() => props?.id.split('-').pop() ?? route.params.id.toString().split('-').pop());
    const errorToDisplay = ref(null);
    const assetDetailMainData = ref<AssetDetailMainDataType>(null);
    const assetDetailAssessmentData = ref(null);
    const assessmentPannelOpen = computed(() => store.getters['assetDetailModule/getAssessmentPannelStatus']);
    
    const togglePannel = () => store.commit('assetDetailModule/toggleAssessmentPannel')

    const runQueryAndParseData = (QUERY, resultType:string) => {
        const {onResult , error} = useQuery(QUERY, {id: assetID}, {fetchPolicy: 'cache-and-network'});
        if (error) {
            errorToDisplay.value = error;
        }
        onResult(async queryResult => {
            if (queryResult.data && queryResult?.data[resultType]) {
                store.commit('assetDetailModule/resetValues');
                store.commit('assetDetailModule/setAssetValues', {data: queryResult.data[resultType], assetType: resultType});
                assetDetailMainData.value = await useAssetDataDetailToMainContent(queryResult.data[resultType]);
                assetDetailAssessmentData.value = await useAssetDataDetailToAssessmentContent(queryResult.data[resultType]);
            }
        })
    }

    const executeQuery =()=>{
        if (assetType.value === 'patent') {
            runQueryAndParseData(PATENT_QUERY, ASSET_TYPES.PATENT.graphqlValue);
        }
        if (assetType.value === 'research_paper') {
            runQueryAndParseData(RESEARCH_PAPER_QUERY, ASSET_TYPES.RESEARCH_PAPER.graphqlValue);
        }
        if (assetType.value === 'research_project') {
            runQueryAndParseData(RESEARCH_PROJECT_QUERY, ASSET_TYPES.RESEARCH_PROJECT.graphqlValue);
        }
        if (assetType.value === 'other') {
            runQueryAndParseData(OTHER_TECHNOLOGY_QUERY, ASSET_TYPES.OTHER.graphqlValue);
        }
    }

    // executeQuery for the first time on load
    executeQuery();
    
    
    // refresh data
    const handleTdsOnProcessed = () => {
        executeQuery();
    }
</script>
<style lang="scss" scoped>
.asset-detail-container {
    display: flex;
    justify-content: space-between;
    .main-content {
        width: 65%;
        justify-content: space-around;
        padding-right: 1.5rem;
        padding: 3rem 2rem;
    }
    .assessment-content{
        width: 35%;
        padding: 3rem 2rem;
        background-color: $grey-modal;
        min-height: 100vh;
        border-right: $border;
        position: sticky;
        top: 0;
        max-height: 100vh;
        overflow: scroll;
        transition: width 0.1s ease-in-out;
    }
    &.pannelClose {
        .main-content {
            width: calc(100% - 80px);
        }
        .assessment-content {
            width: 80px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .assessment-close {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        cursor: pointer;
        .icon {
            position: absolute;
            top: 58px;
            padding: 5px;
            width: 2rem;
            height: 2rem;
        }
        p {
            transform: rotate(-180deg);
            writing-mode: tb-lr;
            writing-mode: vertical-lr;
            font-size: 4rem;
            text-transform: uppercase;
            color: #e7e7e7;
            font-weight: 900;
        }
        
    }
}
</style>