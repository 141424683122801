import gql from "graphql-tag";

export const OTHER_LAST_NUMBERS_BENCHLING = gql`
  query getLastNumbers {
    west1prod_dim_ndn_incrementals {
        last_numbers
    }
}`;

export const OTHER_LAST_NUMBERS_Q = gql`
  query getLastNumbersFromQ ($filter: OtherTechnologyNodeFilterInputType){
    otherTechnologies(filter: $filter, orderBy: "-id", first: 10) { 
      edges {
        node {
          id
          nickname
        }
      }
    }
    
  }
`