export const isNullOrEmptyArray = (value: any): boolean => {
    return value === null || value === undefined || (Array.isArray(value) && value.length === 0);
};

export const generateProductBriefQuestion = (productBrief: any): string => {
    let question = '<p>Hi, I am looking for a packaging solution with the following specifications: </p>';
    question += '<ul class="list-disc pl-5">';
    for(const key in productBrief){
        if(productBrief[key] === null || productBrief[key] === undefined){
            continue;
        }else{
            question += `<li>${key}:&nbsp; ${productBrief[key]}</li>`;
        }
    }
    question += '</ul>';
    return question;

}

export const generateRegulationsQuestion = (regulations: any): string => {
    let question = '<p>Can you please look through packaing regulations with the following creteria: </p>';
    question += '<ul class="list-disc pl-5">';
    for(const key in regulations){
        if(regulations[key] === null || regulations[key] === undefined){
            continue;
        }else{
            question += `<li>${key}:&nbsp; ${regulations[key]}</li>`;
        }
    }
    question += '</ul>';
    return question;
}

export const generateGeneralQuestion = (regulations: any): string => {
    let question = '';
    question += '<ul class="list-disc pl-5">';
    for(const key in regulations){
        if(regulations[key] === null || regulations[key] === undefined){
            continue;
        }else{
            question += `<li>${key}:&nbsp; ${regulations[key]}</li>`;
        }
    }
    question += '</ul>';
    return question;
}