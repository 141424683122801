<template>
  <assetTdsModal :assetPdfFiles="assetPdfFiles"/>
  <template
    v-if="!assetAssessmentHighLevelData.files.length && !assetAssessmentHighLevelData.fields.some(aahld => aahld.content)">
    <div>
      <p class="no-assessment">No Assessment yet</p>
      <div class="edit-assessment on-green" @click="handleEdit">
        <PencilIcon class="icon" />
        <p>Assess asset</p>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="edit-assessment" @click="handleEdit">
      <PencilIcon class="icon" />
      <p>Assess</p>
    </div>
    <template v-for="field in assetAssessmentHighLevelData.fields" :key="field.title">
      <div v-if="field.content" class="mb-4">
        <p class="field-title">{{ field.title }}</p>

        <template v-if="field.inputType == 'number'">
          <span class="value-number"> {{ field.content }} </span>
        </template>

        <template v-if="field.inputType == 'select' || field.inputType == 'multipleSelect'">

          <!-- PROPERTIES -->
          <div class="mb-4" v-if="field.propertyContent">
            <div v-for="(propertyData, index) in field.propertyContent" :key="index" class="mb-3">
              <p class="label">Class:</p>
              <p class="value">{{ propertyData.className }}</p>
              <div v-for="(category, index) in propertyData.category" :key="index" class="property-category">
                <p class="label ml-3">Category:</p>
                <p class="value ml-3">{{ category.categoryName }} </p>
                <ul class="values-list ml-3">
                  <li v-for="(property, index) in category.propertiesNames" :key="index"> {{ property }} </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- STATUS -->
          <div v-else-if="field.title === 'Status'">
            <p class="status" :class="field.content">{{ field.content }}</p>
          </div>

          <!-- REST OF THE PROPERTIES THAT HAS LIST -->
          <ul v-else class="values-list">
            <li v-for="value in field.content" :key="value">{{ value }}</li>
          </ul>
        </template>
        
        <template v-if="field.inputType == 'textarea'">
          <p v-html="field.content" class="notes"></p>
        </template>
      </div>
    </template>
    <p v-if="!!assetAssessmentHighLevelData.files.length" class="field-title">files</p>
    <AssetFilesList v-if="!!assetAssessmentHighLevelData.files.length" :files-to-show="assetAssessmentHighLevelData.files"
      :files-removal="false" :show-label="false" />
  </template>
</template>
<script setup lang="ts">
import { defineProps,computed } from 'vue';
import AssetFilesList from './AssetFilesList.vue';
import { PencilIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { AssetAssessmentHighLevel } from '@/models/assetAssessmentInterfaces';
import AssetTdsModal from './AssetTdsModal.vue';
import {useAssetFilesToPdfFilesOnly} from '@/composables/useAssetFilesToPdfFilesOnly';

const props = defineProps<{ assetAssessmentHighLevelData: AssetAssessmentHighLevel }>()
const store = useStore();

const handleEdit = () => store.commit('assetDetailModule/setEditableOn', { sectionToMakeEditbale: 'highLevel' })


const assetId=computed(()=>store.getters['assetDetailModule/getAssetId']);
const assetPdfFiles=computed(()=>useAssetFilesToPdfFilesOnly(props.assetAssessmentHighLevelData.files))

</script>
<style lang="scss" scoped>
.field-title {
  font-weight: 500;
  text-transform: uppercase;
  color: $font-color-light;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.status {
  background-color: $danger-light;
  color: white;
}

.label {
  font-size: 0.85rem;
  color: $font-color-super-light;
  font-weight: 300;
}

.value {
  font-size: 0.85rem;
  color: rgb(109, 109, 109);
  font-weight: 500;
  padding-bottom: 0.5rem;
  overflow: auto;
  text-transform: uppercase;
  overflow: visible;
}

.property-category {
  border-left: 1px dashed #ddd;
  margin-left: 1.2rem;
}
</style>