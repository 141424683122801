<template>
    <div class="flex items-center pl-4 mb-4">
        <p>Select assets with status: </p> 
    </div> 
    <div v-if="statuses" class="flex px-4 flex-wrap"> 
        <div v-for="status in statuses" :key="status.id"> 
            <input type="checkbox" v-model="selectedStatus" @change="setValueInStore" :value="status.name" :id="status.name" class="hidden peer">
            <label :for="status.name" class="transition-colors duration-200 ease-in-out peer-checked:bg-red-300 peer-checked:text-white cursor-pointer border m-1 px-3 py-2 rounded-lg" >  {{ status.name }} </label>
       </div>
    </div>
</template>
<script setup lang="ts">
import { ASSETS_EVALUATION_STATUS_OPTIONS } from '@/grapql/assetsFilterOptionsQueries';
import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export interface EvaluationStatusInterface {
    id: number, 
    name: string
}

const store = useStore();
const { result } = useQuery(ASSETS_EVALUATION_STATUS_OPTIONS);
const queryResult = computed(() => result?.value ?? null);
const statuses = computed<EvaluationStatusInterface[]>(() => queryResult.value && queryResult.value.assetEvaluationStatuses.edges.length ? queryResult.value.assetEvaluationStatuses.edges.map((edge) => edge.node) : null)

const selectedStatus = ref([]);

const setValueInStore = () => store.commit('assetsModule/setSelectedStatusForFilter', selectedStatus.value );

onMounted(() => {
    const statusFromStore = store.state.assetsModule.selectedStatusForFilter;
    selectedStatus.value = statusFromStore
})
</script>
<style lang="scss">
   
</style>