export const REGULATIONS_FORM_FIELDS = {
    graphqlValue: 'regulations',
    formFields: [
        {label:'Countries', type: 'multiple-select', mutationValue: 'countries', desciption:'In which country(ies) do you intend to market your product?',required:true}, 
        {label:'Application', type: 'select', mutationValue: 'application'},  
    ],
    mandatoryFormFields: ['countries','application'],
    fieldsToQuery: [],
    query: '',
    mutation:'aiModule/ask'
}

export const REGULATIONS_MODIFICATION_FORM_FIELDS = {
    graphqlValue: 'regulationsModify',
    formFields: [
        {label:'Paper Content Percentage', type: 'text', mutationValue: 'paperContentPercentage', desciption:'',required:true}, 
    ],
    mandatoryFormFields: ['paperContentPercentage'],
    fieldsToQuery: [],
    query: '',
    mutation:'aiModule/ask'
}


export enum AI_FORM_TYPE{
    Regulations = 'regulations',
    ProductBrief='productBrief',
    Innovation='innovation',
}


export const PRODUCT_BRIEF_FORM_FIELDS = {
    graphqlValue: 'productBrief',
    formFields: [
        {label:'Total GSM', type: 'number', mutationValue: 'totalGsm'},  
        {label:'Paper Content', type: 'number', mutationValue: 'paperContent',required:true},  
        {label:'WVTR', type: 'number', mutationValue: 'wvtr'},  
        {label:'OTR', type: 'number', mutationValue: 'otr'},  
        {label:'WVTR Conditions', type: 'string', mutationValue: 'wvtrConditions'},  
        {label:'OTR Conditions', type: 'string', mutationValue: 'otrConditions'},  
        {label:'Heat Seal', type: 'number', mutationValue: 'heatSeal'},  
        {lable: 'Model', type: 'select', mutationValue: 'model', required: true},
    ],
    mandatoryFormFields: ['paperContent','model'],
    rangeFormFields: ['totalGsm','paperContent','heatSeal'],
    fieldsToQuery: [],
    query: '',
    mutation:'aiModule/ask'
}

export enum ContentType{
    TEXT = "TEXT",
    LIST = "LIST",
    TABLE = "TABLE",
    SANDWICH = "SANDWICH",
}
export enum MessageType{
    QUESTION = "QUESTION",
    ANSWER = "ANSWER",
    ERROR = "ERROR",
}