<template>
    <div>
        <!-- <LoaderPage v-if="extractInProgress" loader-text="Submitting Form" gif-position="center"/> -->
        <div class="form-container m-4 mt-0"> 
            <FormErrorToast :is-showing="!!error" title="Warning!" :subtitle="error" toast-type="warning" />
            <form class="add-asset-form mt-4" >
                <div>
                    <div v-if="tdsOutputError" class="one-five-orange text-white p-2 rounded mb-3">
                        <p class="flex items-start">
                            <ExclamationTriangleIcon class="icon icon-mid thick inline stroke-white" />
                            <span class="ml-2">
                                <strong>Extraction Output Error: </strong><br/>
                                {{ tdsOutputError }}
                            </span>
                        </p>
                    </div>
                    <div class="bg-slate-100 p-2 rounded">
                        <strong>Note: </strong><br/>
                        <ul class="list-disc ml-4">
                            <li class="mb-1">Only selected records will be <strong>inserted</strong>, others will be skipped.</li>
                            <li class="mb--1">If a <strong>PROPERTY</strong> with <strong>VALUE1</strong> already exists, the record will be skipped.</li>
                        </ul>
                    </div>
                    <div class="form-separator col-span-3"><p class="form-subtitle">Process Properties</p></div>
                    <div class="overflow-y-auto">
                        <table class="table-auto border border-slate-100 text-xs">
                            <thead>
                                <tr class="text-left">
                                    <th class="pl-1">ADD</th>
                                    <th class="p-2">PROPERTY</th>
                                    <th class="p-2">VALUE_TYPE</th>
                                    <th class="p-2">VALU1</th>
                                    <th class="p-2">VALUE2</th>
                                    <th class="p-2">VALUE_UNIT</th>
                                    <th class="p-2">EXTRA_INFORMATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item) in vModels['properties']" :key="item"  :class="{ 'bg-slate-50 line-through select-none': item['selected'].value===false }" >
                                    <td class="p-2">
                                        <input type="checkbox" v-model="item['selected'].value">
                                    </td>
                                    <td class="w-full p-2 min-w-[250px]">
                                        <input class="w-full" type="text" v-if="item['selected'].value!==false"  v-model="item['quantitativeProperty'].value" >
                                        <span v-else> {{item['quantitativeProperty'].value}} </span>
                                    </td>
                                    <td class="w-full p-2 min-w-[150px]">
                                        <select class="w-full" v-if="item['selected'].value!==false" v-model="item['valueType'].value">
                                            <option v-for="valueType in valueTypes" :value="valueType.value" :key="valueType.key">
                                                {{ valueType.value }}
                                            </option>
                                        </select>
                                        <span v-else> {{item['valueType'].value}} </span>
                                    </td>
                                    <td class="w-full p-2 min-w-[150px]">
                                        <input class="w-full" type="text" v-if="item['selected'].value!==false" v-model="item['value1'].value">
                                        <span v-else> {{item['value1'].value}} </span>
                                    </td>
                                    <td class="w-full p-2 min-w-[150px]">
                                        <input class="w-full" type="text" v-if="item['selected'].value!==false" v-model="item['value2'].value">
                                        <span v-else> {{item['value2'].value}} </span>
                                    </td>
                                    <td class="w-full p-2 min-w-[150px]">
                                        <input class="w-full" type="text" v-if="item['selected'].value!==false" v-model="item['valueUnit'].value">
                                        <span v-else> {{item['valueUnit'].value}} </span>
                                    </td>
                                    <td class="w-full p-2">
                                        <input class="w-full" type="text" v-if="item['selected'].value!==false" v-model="item['extraInformation'].value">
                                        <span v-else> {{item['extraInformation'].value}} </span>
                                    </td>
                                </tr>
                                <tr v-if="vModels['properties'].length==0" class="w-full">
                                    <td colspan="6" class="w-full p-2"><p>No properties extracted</p></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div class="form-separator col-span-3"><p class="form-subtitle">Process Applications</p></div>
                    <table class="table-auto border border-slate-100 text-xs">
                        <thead>
                            <tr class="text-left">
                                <th class="pl-1">ADD</th>
                                <th class="p-2">APPLICATION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in vModels['applications']" :key="item"  :class="{ 'bg-slate-50 line-through select-none': item['selected'].value===false }" >
                                <td class="p-2">
                                    <input type="checkbox" v-model="item['selected'].value">
                                </td>
                                <td class="w-full p-2">
                                    <input class="w-full min-w-[250px]" type="text" v-if="item['selected'].value!==false"  v-model="item['application'].value" >
                                    <span v-else> {{item['application'].value}} </span>
                                </td>
                            </tr>
                            <tr v-if="vModels['applications'].length==0" class="w-full">
                                <td class="w-full p-2"><p>No applications extracted</p></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="form-separator col-span-3"><p class="form-subtitle">Process Certificates</p></div>
                    <table class="table-auto border border-slate-100 text-xs">
                        <thead>
                            <tr class="text-left">
                                <th class="pl-1">ADD</th>
                                <th class="p-2">CERTIFICATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in vModels['certificates']" :key="item"  :class="{ 'bg-slate-50 line-through select-none': item['selected'].value===false }" >
                                <td class="p-2">
                                    <input type="checkbox" v-model="item['selected'].value">
                                </td>
                                <td class="w-full p-2">
                                    <input class="w-full min-w-[250px]" type="text" v-if="item['selected'].value!==false"  v-model="item['certificate'].value" >
                                    <span v-else> {{item['certificate'].value}} </span>
                                </td>
                            </tr>
                            <tr v-if="vModels['certificates'].length==0" class="w-full">
                                <td class="w-full p-2"><p>No certificates extracted</p></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </form>
        </div>        
    </div>  
</template>
<script setup lang="ts">
import { computed,defineProps } from 'vue';
import { useStore } from 'vuex';
import {ASSET_TDS_PROCESS_FIELDS} from '@/models/assetTdsFormFields';
import FormErrorToast from '@/components/FormErrorToast.vue';
import { InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';



const store = useStore();

const props = defineProps(["valueTypes"]);

const tds = computed(()=> store.getters['assetTdsModule/getTempTdsForProcess']);
const error = computed(()=>store.getters['assetTdsModule/getProcessError']);
const tdsOutputError = computed(()=> store.getters['assetTdsModule/getTempoTdsOutputError']);

// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => ASSET_TDS_PROCESS_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: ASSET_TDS_PROCESS_FIELDS.graphqlValue,
    mutation: 'assetTdsModule/setProcessSingleValue'
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        if(fieldName=="properties"){
            object[fieldName]=[];
            tds.value[fieldName].forEach((element,index) => {
                object[fieldName][index] = {}; // Initialize the object at the index
                object[fieldName][index]['selected']= computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['selected'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'selected' , value: value});
                        }
                    });

                Object.entries(element).forEach(([key,value])=>{
                    object[fieldName][index][key]= computed({
                            get() {
                                return store.state.assetTdsModule[formFieldOptions.base][fieldName][index][key];
                            },
                            set(value) {
                                store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: key , value: value});
                            }
                        })
                });
            });
        }else if(fieldName=="applications"){
            object[fieldName]=[];
            Object.entries(tds.value[fieldName]).forEach(([index,value])=>{
                object[fieldName][index] = {}; //Initialize the object at the index
                object[fieldName][index]['selected']= computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['selected'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'selected' , value: value});
                        }
                    });
                object[fieldName][index]['application'] = computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['application'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'application' , value: value});
                        }
                    });
            });
        }else if(fieldName=="certificates"){
            object[fieldName]=[];
            Object.entries(tds.value[fieldName]).forEach(([index,value])=>{
                object[fieldName][index] = {}; //Initialize the object at the index
                object[fieldName][index]['selected']= computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['selected'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'selected' , value: value});
                        }
                    });
                object[fieldName][index]['certificate'] = computed({
                        get() {
                            return store.state.assetTdsModule[formFieldOptions.base][fieldName][index]['certificate'];
                        },
                        set(value) {
                            store.commit(formFieldOptions.mutation, { field: fieldName,index: index, key: 'certificate' , value: value});
                        }
                    });
                

            });
        }
    });
    return object;
}
const vModels = formFields(formFieldOptions)
</script>