export const ASSET_TDS_FIELDS = {
    graphqlValue: 'extract',
    formFields: [
        {label:'Select or Upload', type: 'bool', mutationValue: 'isNewUpload', required: true }, 
        {label:'Asset File', type: 'select', mutationValue: 'assetFile'}, 
        {label:'File', type: 'file', mutationValue: 'file'},  
    ],
    mandatoryFormFields: ['isNewUpload','assetFile','file'],
    fieldsToQuery: [],
    query: 'assetTdsModule/extract'
}

export const ASSET_TDS_PROCESS_FIELDS={
    graphqlValue: 'process',
    formFields: [
        {label:'Properties', type: 'list', mutationValue: 'properties', required: true }, 
        {label:'Applications', type: 'list', mutationValue: 'applications', required:true}, 
        {label:'Certificates', type: 'list', mutationValue: 'certificates', required:true},  
    ],
    mandatoryFormFields: ['properties','applications','certificates'],
    fieldsToQuery: [],
    createMutation: 'assetTdsModule/process'
}




