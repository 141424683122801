export const useAssetTdsToProcessFormData = (tempTds) => {
    const output  = {
        id: tempTds.id,
        properties: tempTds.result.properties.map(item => ({
            quantitativeProperty: item.quantitativeProperty,
            valueType: item.valueType,
            value1: item.value1,
            value2: item.value2,
            valueUnit: item.valueUnit,
            extraInformation: item.extraInformation,
            selected: item.selected
        })),
        applications: tempTds.result.applications.map(item=>({application:item, selected:true})),
        certificates: tempTds.result.certificates.map(itme=>({certificate:itme, selected:true}))
    };
    return output;
}