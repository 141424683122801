<template>
    <div :class="{'range-input-error': hasError}" class="range-input group flex items-center space-x-0 border border-gray-300 rounded  bg-white shadow-xs" @mouseover="showReset = true" @mouseleave="showReset = false">
      <input
        type="number"
        :value="startValue"
        @input="updateValue(0, $event.target.value)"
        :min="min"
        :max="max"
        class="w-full text-center"
        style="outline: none;border:none;"
      />
      <span>-</span>
      <input
        type="number"
        :value="endValue"
        @input="updateValue(1, $event.target.value)"
        :min="min"
        :max="max"
        class="w-full text-center"
        style="outline: none;border:none;"
      />
      <button  @click="resetValues" class="ml-0 p-1 bg-white text-white rounded-sm text-sm shadow-none group-hover:bg-slate-100 group-hover:text-slate-300 group-focus-within:bg-slate-100 group-focus-within:text-slate-300" title="reset">x</button>
    </div>
  </template>
  
  <script setup>
  import { computed, toRefs ,defineEmits,defineProps, ref} from 'vue';

  const props = defineProps({
    modelValue: {
      type: Array,
      required: true,
      validator: (value) => {
        // Check if the value is an array of length 2 or an empty array
        if (!Array.isArray(value)) return false;
        if (value.length === 0) return true;
        if (value.length !== 2) return false;
        // Check if each value is either null or a finite number
        return value.every(v => v === null || Number.isFinite(v));
      },
    },
    min: {
      type: Number,
      default: Number.NEGATIVE_INFINITY,
    },
    max: {
      type: Number,
      default: Number.POSITIVE_INFINITY,
    },
  });
  
  const emit = defineEmits(['update:modelValue']);
  
  const { modelValue, min, max } = toRefs(props);

  // Create a ref to control the visibility of the reset button
  const showReset = ref(false);
  
  const startValue = computed({
    get: () => modelValue.value[0],
    set: (val) => {
      val = parseFloat(val);
      val = Math.min(Math.max(val, min.value), max.value); // Clamp value between min and max
      let newValue = [val, modelValue.value[1] ?? max.value];
      // if (newValue[0] > newValue[1]) newValue[0] = newValue[1];
      emit('update:modelValue', newValue);
    },
  });
  
  const endValue = computed({
    get: () => modelValue.value[1],
    set: (val) => {
      val = parseFloat(val);
      val = Math.min(Math.max(val, min.value), max.value); // Clamp value between min and max
      let newValue = [modelValue.value[0]?? min.value, val];
      // if (newValue[1] < newValue[0]) newValue[1] = newValue[0];
      emit('update:modelValue', newValue);
    },
  });

  const hasError = computed(()=>{
    if (modelValue.value[0] > modelValue.value[1]) {
      return true;
    }
    return false;
  });
  
  const updateValue = (index, value) => {
    value = parseFloat(value);
    if (index === 0) {
      startValue.value = value;
    } else {
      endValue.value = value;
    }
  };

  const resetValues = () => {
    startValue.value = min.value;
    endValue.value = max.value;
    emit('update:modelValue', []);
  };

</script>
<style scoped>
  .range-input-error {
    border-color: rgb(255,0,0,0.5) !important;
  }
</style>