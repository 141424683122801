import { useMutation } from "@vue/apollo-composable";
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from "vue-toastification";
import { DesignCreateWithLayersMutationInput, DesignUpdatePartialMutationInput, ProgramVersionForUpdateForm, ProgramVersionTargetsInterface, ProgramVersionUpdatePartialMutationInput, TeamMemberForUpdate, TeamMemberInterface } from "@/models/programVersionsInterface";
import { DESIGN_CREATE_WITH_LAYERS, DESIGN_UPDATE_PARTIAL, LAYER_DELETE, PROGRAM_VERSION_UPDATE_PARTIAL, TARGET_DELETE, TEAM_DELETE } from "@/grapql/programVersionDetailMutations";
import moment from "moment";
import { format } from "date-fns";

provideApolloClient(apolloClient);
const toast = useToast();

const getDefaultState = () => {
  return {
        programVersion: {
            goal: null,
            creationDate: new Date(),
            description: null,
            program: null,
            version: null,
            applicationTypes: [],
            programVersionTeam: [],
            programVersionTargets: [],
        },
        programVersionFromBE: null,
        programVersionForEdition: null, 
        programVersionTeamForEdition: null, 
        programVersionTargetsForEdition: null,
        designForEdition: null, 
        newDesign: {
            nickname: null,
            description: null,
            programVersion: null,
            layers: [],
        },
        errorDuringMutation: {
            programVersionUpdatePartial: null,
            designCreate: null,
            designUpdate: null
        },
        targetsToDelete: [],
        teamMembersToDelete: [],
        layersToDelete: []
    };
};
const state = getDefaultState();

const mutations = {
    clearErrors(state, payload: { type: string }) {
        state.errorDuringMutation[payload.type] = null;
    },

    setErrorOnMutationResponse(state, payload: { error: string; type: string }) {
        if (payload.error.includes("already exists")) {
        state.errorDuringMutation[payload.type] =
            "Ops! what you are trying to create already exists";
        } else {
        state.errorDuringMutation[payload.type] = payload.error;
        }
    },

    resetNewDesignValues(state) {
        state.newDesign = getDefaultState().newDesign;
    },

    setProgramVersionValuesFromBE(state, payload: { programVersion }) {
        state.programVersionFromBE = payload.programVersion;
        state.newDesign.programVersion = payload.programVersion.id;
    },

    parseBeValuesForEdition(state) {
        const parsedProgram:ProgramVersionForUpdateForm = {
            id: state.programVersionFromBE.id,
            version: state.programVersionFromBE.version,
            program: state.programVersionFromBE.program.id,
            creationDate: state.programVersionFromBE.creationDate,
            goal: state.programVersionFromBE.goal,
            description: state.programVersionFromBE.description,
            applicationTypes: state.programVersionFromBE.applicationTypes.edges.map(edge => edge.node.id),
        }
        state.programVersionForEdition = parsedProgram;
    },

    parseBeTeamValuesForEdition(state) {
        const beTeam = state.programVersionFromBE.team.edges.map(edge => edge.node);
        const team:TeamMemberInterface[] = [];
        if(beTeam.length) {
            beTeam.map(teamMember => {
                const newTeamMember = {
                    id: teamMember.id,
                    user: teamMember.user.id,
                    teamRoleType: teamMember.teamRoleType.id
                }
                team.push(newTeamMember)
            });
        }
        state.programVersionTeamForEdition = team;
    },

    parseBeTargetsValuesForEdition(state) {
        const beTargets = state.programVersionFromBE.targets.edges.map(edge => edge.node);
        const targets:ProgramVersionTargetsInterface[] = [];
        if (beTargets.length) {
            beTargets.map(target => {
                const newTarget = {
                    id: target.id,
                    creationDate: moment(target.creationDate).toDate(),
                    targetCategoryType: target.targetCategoryType.id,
                    targetProperty: target.targetProperty.id,
                    targetValueType: target.targetValueType.id,
                    targetValue1: target.targetValue1,
                    targetValue2: target.targetValue2 ? target.targetValue2 : null,
                    targetValueUnit: target.targetValueUnit ? target.targetValueUnit.id : null,
                    currentValue: target.currentValue,
                }
                targets.push(newTarget)
            })
        }
        state.programVersionTargetsForEdition = targets;
    },

    parseBeDesignForEdition(state, payload: {id: number}) {
        const beDesigns = state.programVersionFromBE.designs.edges.map(edge => edge.node);
        const beDesign = beDesigns.find(design => design.id == payload.id);
        const design = {
            id: beDesign.id, 
            nickname: beDesign.nickname,
            description: beDesign.description,
            status: beDesign.status.id, 
            statusReason: beDesign.statusReason,
            layers: beDesign.layers.edges.map(({ node: {asset, __typename, layerType, thicknessValueType, thicknessUnitType, ...rest}}) => ({
                asset: asset ? asset.id : null, 
                layerType: layerType.id,
                thicknessValueType: thicknessValueType.id,
                thicknessUnitType: thicknessUnitType.id,
                ...rest,
            })).sort((layerA, layerB) => layerA.layerOrder - layerB.layerOrder)
        }
        state.designForEdition = design;
        
    },

    async removeTargets(state) {
        const programVersionTargetsWithoutRemovals = state.programVersionTargetsForEdition.filter(target => !state.targetsToDelete.includes(target.id))
        state.programVersionTargetsForEdition = programVersionTargetsWithoutRemovals;
        state.targetsToDelete = [];
    },

    async removeTeamToDelete(state) {
        const programVersionTeamWithoutRemovals = state.programVersionTeamForEdition.filter(teamMember => !state.teamMembersToDelete.includes(teamMember.id))
        state.programVersionTeamForEdition = programVersionTeamWithoutRemovals;
        state.teamMembersToDelete = [];
    },

    async removeLayersToDelete(state) {
        const layersWithoutRemovals = state.designForEdition.layers.filter(layer => !state.layersToDelete.includes(layer.id))
        state.designForEdition.layers = layersWithoutRemovals;
        state.layersToDelete = [];
    },

    updateSingleProgramVersionValue(state, payload: {programField: string, value: Date | string | null }) {
        state.programVersionForEdition[payload.programField] = payload.value;
    },
  
};

const actions = {

    // CREATE NEW DESIGN
    designCreateWithLayers({ state, commit }) {
        return new Promise<void>((resolve) => {
            const { mutate: designCreateWithLayers, onDone, onError} = useMutation(DESIGN_CREATE_WITH_LAYERS);
            const design: DesignCreateWithLayersMutationInput = Object.assign({}, state.newDesign );
            design.programVersion = state.programVersionFromBE.id;
            designCreateWithLayers({ input: design });
            onDone(async () => {
                await commit("clearErrors", { type: "designCreate" });
                toast.success(`Programme updated`, {position: POSITION.BOTTOM_LEFT, timeout: 1524,});
                await apolloClient.refetchQueries({include: ['getProgramVersion']});
                resolve();
            });
            onError(async (error) => {
                commit("setErrorOnMutationResponse", {error: error.message, type: "designCreate"});
                resolve();
            });
        });
    },

    // DESIGN UPDATE PARTIAL 
    designUpdatePartial({ state, commit }) {
        return new Promise<void>((resolve) => {
            const { mutate: designUpdatePartial, onDone, onError} = useMutation(DESIGN_UPDATE_PARTIAL);
            const design: DesignUpdatePartialMutationInput = Object.assign({}, state.designForEdition );
            designUpdatePartial({ input: design });
            onDone(async () => {
                await commit("clearErrors", { type: "designUpdate" });
                toast.success(`Design updated`, {position: POSITION.BOTTOM_LEFT, timeout: 1524,});
                await apolloClient.refetchQueries({include: ['getProgramVersion']})
                    // .then(() => commit("parseBeDesignForEdition"))
                    .then(() => resolve())
                
            });
            onError(async (error) => {
                commit("setErrorOnMutationResponse", {error: error.message, type: "designUpdate"});
                resolve();
            });
        });
    },

    // LAYER DELETE
    layerDelete({ state, commit }) {
        return new Promise<void>((resolve) => {
            const { mutate: layerDelete, onDone, onError} = useMutation(LAYER_DELETE);
            if (state.layersToDelete.length > 0) {
                state.layersToDelete.map((layerToDelete: number) => {
                    const idToDelete = {id: layerToDelete}
                    layerDelete({ input: idToDelete });
                    onDone(async () => {
                        await commit('removeLayersToDelete');
                        resolve();
                    });
                    onError(async (error) => {
                        commit("setErrorOnMutationResponse", {error: error.message, type: "designUpdate"});
                        resolve();
                    });
                })
            }
            else {
                resolve();
            }
            
        });
    },

    // TARGET DELETE
    programVersionTargetDelete({ state, commit }) {
        return new Promise<void>((resolve) => {
            const { mutate: programVersionTargetDelete, onDone, onError} = useMutation(TARGET_DELETE);
            if (state.targetsToDelete.length) {
                state.targetsToDelete.map((targetToDelete: number) => {
                    const idToDelete = {id: targetToDelete}
                    programVersionTargetDelete({ input: idToDelete });
                    onDone(async () => {
                        await commit('removeTargets');
                        resolve();
                    });
                    onError(async (error) => {
                        commit("setErrorOnMutationResponse", {error: error.message, type: "programVersionUpdatePartial"});
                        resolve();
                    });
                })
            }
            else {
                resolve();
            }
            
        });
    },


    // TEAM MEMBER DELETE 
    programVersionTeamDelete({ state, commit }) {
        return new Promise<void>((resolve) => {
            const { mutate: programVersionTeamDelete, onDone, onError} = useMutation(TEAM_DELETE);
            if (state.teamMembersToDelete.length) {
                state.teamMembersToDelete.map((teamToDelete: number) => {
                    const idToDelete = {id: teamToDelete}
                    programVersionTeamDelete({ input: idToDelete });
                    onDone(async () => {
                        await commit('removeTeamToDelete');
                        resolve();
                    });
                    onError(async (error) => {
                        commit("setErrorOnMutationResponse", {error: error.message, type: "programVersionUpdatePartial"});
                        resolve();
                    });
                })
            }
            else {
                resolve();
            }
            
        });
    },

    // PROGRAM VERSION PARTIAL UPDATE
    programVersionUpdatePartial({ state, commit }) {
        return new Promise<void>((resolve) => {
            const { mutate: programVersionUpdatePartial, onDone, onError} = useMutation(PROGRAM_VERSION_UPDATE_PARTIAL);
            const programVersion = Object.assign({}, state.programVersionForEdition );
            delete programVersion.program;
            const programVersionInput: ProgramVersionUpdatePartialMutationInput = programVersion;
            if (state.programVersionTeamForEdition.length) {
                programVersionInput.programVersionTeam = state.programVersionTeamForEdition
            }
            if (state.programVersionTargetsForEdition.length ) {
                programVersionInput.programVersionTargets = state.programVersionTargetsForEdition
                programVersionInput.programVersionTargets.map(target => target.creationDate = format(target.creationDate, 'yyyy-MM-dd'))
            }
            programVersionUpdatePartial({ input: programVersionInput });
            onDone(async () => {
                await commit("clearErrors", {
                  type: "programVersionUpdatePartial",
                });
                toast.success(`New design created`, {
                  position: POSITION.BOTTOM_LEFT,
                  timeout: 1524,
                });
                await apolloClient.refetchQueries({include: ["getProgramVersion"]})
                    .then(() => commit("parseBeValuesForEdition"))
                    .then(() => commit("parseBeTeamValuesForEdition"))
                    .then(() => commit("parseBeTargetsValuesForEdition"))
                    .then(() => resolve())
            });
            onError(async (error) => {
                commit("setErrorOnMutationResponse", {error: error.message, type: "programVersionUpdatePartial"});
                resolve();
            });
        });
    },

    
};

const getters = {

    getProgramName(state) { 
        return state.programVersionFromBE.program.name
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
