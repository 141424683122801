<template>
    <div class="border border-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4 grid grid-cols-2">
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('totalGsm') && formSubmited }">
            <label class="block">Total GSM <small>(g/m2)</small></label>
            <RangeInput v-model="vModels['totalGsm'].value" :min="0"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('paperContent') && formSubmited }">
            <label class="block">Paper Content * <small>(%)</small></label>
            <RangeInput v-model="vModels['paperContent'].value" :min="0" :max="100"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('wvtr') && formSubmited }">
            <label class="block">WVTR <small>(g/m2*24h)</small></label>
            <input type="number" class="w-full" v-model="vModels['wvtr'].value" min="0"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('otr') && formSubmited }">
            <label class="block">OTR <small>(cm3/m2*24h)</small></label>
            <input type="number" class="w-full" v-model="vModels['otr'].value" min="0"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('wvtrConditions') && formSubmited }">
            <label class="block">WVTR Conditions <small>(°C : g/m²/day)</small></label>
            <!-- <input type="text" class="w-full" v-model="vModels['wvtrConditions'].value"/> -->
             <select class="w-full" v-model="vModels['wvtrConditions'].value">
                <option value="" selected>select wvtr conditions</option>
                <option v-for="condition in dropdowns.wvtrConditions" :key="condition" :value="condition">{{condition}}</option>
            </select>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('otrConditions') && formSubmited }">
            <label class="block">OTR Conditions <small>(°C : cc/m²/day)</small></label>
            <!-- <input type="text" class="w-full" v-model="vModels['otrConditions'].value"/> -->
            <select class="w-full" v-model="vModels['otrConditions'].value">
                <option value="" selected>select otr conditions</option>
                <option v-for="condition in dropdowns.otrConditions" :key="condition" :value="condition">{{condition}}</option>
            </select>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('heatSeal') && formSubmited }">
            <label class="block">Heat Seal <small>(N/15mm)</small></label>
            <RangeInput v-model="vModels['heatSeal'].value" :min="0"/>
        </div>
        <div class="m-2" :class="{ 'error-form-field': errorFields.includes('model') && formSubmited }">
            <label class="block">Model *</label>
            <select v-model="vModels['model'].value" class="w-full">
                <option selected>select an ai model</option>
                <option v-for="model in dropdowns.aiModels" :key="model" :value="model">{{model}}</option>
            </select>   
        </div>
        
        <div class="m-2 mt-5 col-span-2">
            <div class="flex">
                <button @click="handleAsk" class="ml-auto py-2 px-5 rounded-none  hover:shadow-md active:shadow-inner button cancel" type="button">
                    <PlayIcon class="icon icon-small" />
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, defineProps,ref ,watch} from 'vue';
import { useStore } from 'vuex';
import { PlayIcon } from '@heroicons/vue/24/outline';
import { PRODUCT_BRIEF_FORM_FIELDS } from '@/models/aiModels';
import { isNullOrEmptyArray } from "@/utils/utils";
import RangeInput from "@/components/shared/RangeInput.vue";
import { useQuery } from '@vue/apollo-composable';
import { PRODUCTBRIEF_FEED_DATA } from '@/grapql/aiQuery';

const store = useStore();

// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => PRODUCT_BRIEF_FORM_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: PRODUCT_BRIEF_FORM_FIELDS.graphqlValue,
    mutation: 'aiModule/setProductBriefSingleValue',
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
            object[fieldName] = computed({
                get() {
                    return store.state.aiModule.ask[formFieldOptions.base][fieldName];
                },
                set(value) {
                    store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
                }
            })
    });
    return object;
}
const vModels = formFields(formFieldOptions);


const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.aiModule.ask[PRODUCT_BRIEF_FORM_FIELDS.graphqlValue]);
const formIsValid = computed(() =>{
    // Check if all mandatory fields are valid
    const areMandatoryFieldsValid = PRODUCT_BRIEF_FORM_FIELDS.mandatoryFormFields.every(mf => {
        return !isNullOrEmptyArray(valuesSubmitted.value[mf]);
    });

    // Check if range fields are valid if any range value is provided
    const areRangeFieldsValid = PRODUCT_BRIEF_FORM_FIELDS.rangeFormFields.every(rangeField => {
        const rangeValues = valuesSubmitted.value[rangeField];
        if (rangeValues && rangeValues.length > 0) {
        const [startValue, endValue] = rangeValues;
        return startValue !== null && endValue !== null && startValue <= endValue;
        }
        return true; // If no value provided, consider it valid
    });

    // Return true only if both mandatory fields and range fields are valid
    return areMandatoryFieldsValid && areRangeFieldsValid;
    });

const errorFields = computed(() =>{
    // Collect fields that are invalid
    const invalidMandatoryFields = PRODUCT_BRIEF_FORM_FIELDS.mandatoryFormFields.filter(mf => {
        return isNullOrEmptyArray(valuesSubmitted.value[mf]);
    });

    const invalidRangeFields = PRODUCT_BRIEF_FORM_FIELDS.rangeFormFields.filter(rangeField => {
        const rangeValues = valuesSubmitted.value[rangeField];
        if (rangeValues && rangeValues.length > 0) {
        const [startValue, endValue] = rangeValues;
        return startValue === null || endValue === null || startValue > endValue;
        }
        return false; // If no value provided, consider it valid
    });

    // Combine the results
    return [...invalidMandatoryFields, ...invalidRangeFields];
});


const handleAsk = async () => {
    formSubmited.value = true;
    if(formIsValid.value){
        formSubmited.value=false;
        await store.dispatch("aiModule/askProductBrief");
    }
}

// get data tables values
const dropdowns = ref({'otrConditions':[],'wvtrConditions':[],'aiModels':[]});
const {onResult, error} = useQuery(PRODUCTBRIEF_FEED_DATA);
if (error) {
console.log(error);
}
onResult(async queryResult => {
    if (queryResult.data) {
        dropdowns.value.otrConditions = queryResult.data.otrConditions || [];
        dropdowns.value.wvtrConditions = queryResult.data.wvtrConditions || [];
        dropdowns.value.aiModels = queryResult.data.aiModels || [];
    }
});

</script>