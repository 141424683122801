<template>
    <div class="title-menu-header">
        <h1 class="view-title">{{ programVersionName }}</h1>
        <div class="action-icons flex">
            <div class="round-action-icon" @click="toggleForm('edit')">
                <PencilIcon class="icon icon-small" />
            </div>
            <div class="round-action-icon p-2 opacity-30 cursor-not-allowed" @click="toggleForm('design')">
                <LayerIcon />
            </div>
            <div class="round-action-icon" @click="$router.go(-1)">
                <ArrowUturnLeftIcon class="icon icon-small" />
            </div>

        </div>
    </div>
    <ProgramVersionDesignForm :is-open="isDesignFormOpen" @close-modal="isDesignFormOpen = false"/>
    <ProgramVersionsForm :is-open="isEditFormOpen" :on-edit="true" @close-edit="isEditFormOpen = false"/>
</template>
<script setup lang="ts">
import { ArrowUturnLeftIcon, PencilIcon } from '@heroicons/vue/24/outline';
import ProgramVersionDesignForm from './ProgramVersionDesignForm.vue';
import LayerIcon from './LayerIcon.vue';
import { defineProps, ref, computed } from 'vue';
import ProgramVersionsForm from './ProgramVersionsForm.vue';
import { useStore } from 'vuex';

defineProps({
    programVersionName: String,
})

const store = useStore();
const isDesignFormOpen = ref(false);
const isEditFormOpen = ref(false);

const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed']);
const toggleSidebar = () => store.commit('authModule/toogleSidebar');

const toggleForm = (formType: string) => {
    if( formType == "design") {
        isDesignFormOpen.value = !isDesignFormOpen.value
    }
    if( formType == "edit") {
        isEditFormOpen.value = !isEditFormOpen.value
    }
    if (!collapsed.value) {
        toggleSidebar();
    }
}

</script>
