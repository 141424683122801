
import { TechnologyAssetInterface, TechnologyAssetAsTableRowInterface } from '@/models/technologyAssetsInterface';
import { useNodeContentToString } from './useNodeContent';
    
export const useAssetDataToTableRow = ( asset:TechnologyAssetInterface ):TechnologyAssetAsTableRowInterface => {
    const tableRowAsset:TechnologyAssetAsTableRowInterface  = {
        id: asset.id,
        nickname: asset.nickname,
        title: asset.title, 
        abstract: asset.abstract,
        description: asset.description,        
        assetType: formatAssetType(asset.assetType.toLowerCase()),
        sources: useNodeContentToString(asset?.sources.edges, 'name'),
        publicationDate: asset.publicationDate,
        url: asset.url ? `<p>${asset.url}</p>` : null,
        tags: asset.tags.join(', '),
        notes: asset.notes, 
        applicationTypes: useNodeContentToString(asset?.applicationTypes.edges, 'name'),
        materials: useNodeContentToString(asset?.materials.edges, 'name'),
        properties: useNodeContentToString(asset?.properties.edges, 'name'), 
        processingTechniques: useNodeContentToString(asset?.processingTechniques.edges, 'name'),
        standards: useNodeContentToString(asset?.standards.edges, 'code'), 
    };
    return tableRowAsset;
}

export const formatAssetType = (assetType:string) => {
    return assetType == 'research_project' ? 'External Opportunity'
            : assetType == 'other' ? 'Commercial & Other'
            : (assetType.charAt(0).toUpperCase() + assetType.slice(1)).replace('_', ' ');
}



export const parseAssetsResponse = ( assetsRawList ) => {
    const assetsToParse = assetsRawList.map(a => a.node);
    return assetsToParse.map(asset => useAssetDataToTableRow(asset));
}

export const parseAssetToExtendedContent = (asset )  => {
   const keesToKeep =  ['id', 'nickname', 'title', 'abstract', 'description', 'notes'];
   return keesToKeep.reduce((obj, key:string) => ({ ...obj, [key]: asset[key]}), {});
}

export const parseAssetToSummarizedContent = (asset )  => {
    const keesToKeep =  ['sources', 'publicationDate', 'applicationTypes', 'url', 'tags'];
    return keesToKeep.reduce((obj, key:string) => ({ ...obj, [key]: asset[key]}), {});
}
 
