<template> 

<div class="table-container" v-show="assets.length">
    <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="assets"
        styleClass="vgt-table"
        :fixed-header="false"
        :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
        :sort-options="{ enabled: true, }"
        v-on:selected-rows-change="selectionChanged"
        v-on:cell-click="onCellClick"
        :totalRows="totalRecords"
        :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
            setCurrentPage: serverParams.page,
            perPageDropdown: [10, 20, 50],
            dropdownAllowAll: false,
        }"
        v-on:page-change="onPageChange"
        v-on:per-page-change="onOnPerPageChange"
        :isLoading="isLoading"
        >
        <template #selected-row-actions >
            <div class="ml-3 pl-3 border-s flex items-center cursor-pointer" @click="compareAssets">
                <ClipboardDocumentCheckIcon class="icon icon-small white thick mr-1" />
                <p>Compare</p>
            </div>
        </template>
    </vue-good-table>
    <TechnologyAssetsComparisonCriteriaModal  />
</div>

</template>
<script setup lang="ts">
import { defineProps, defineEmits, computed, ref } from 'vue';
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
import TechnologyAssetsComparisonCriteriaModal from '@/components/TechnologyAssetsComparisonCriteriaModal.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { GoodTablePageChangeInterface, GoodTablePerPageParamsInterface, GoodTableServerParamsInterface,  TechnologyAssetAsTableRowInterface } from '@/models/technologyAssetsInterface';
import { AssetSimpreInterface } from '@/models/assetsComparisonTableInterfaces';

const props = defineProps<{
    assets:  TechnologyAssetAsTableRowInterface[];
    hasNextPage: boolean,
    totalRecords: number,
    isLoading: boolean,
    serverParams: GoodTableServerParamsInterface
}>();

const emits = defineEmits(['onPageChange', 'onPerPageChange']);

const router = useRouter();
const store = useStore();

const columns = computed(() => store.state.assetsModule.goodTableColumns);

const onCellClick = (params) => {
    if (params.column.field == 'url' && params.row.url) {
        const link = params.row.url.replace('<p>', '').replace('</p>', '') 
        window.open(link, '_blank')
    } else {
        const assetType = 
        params.row.assetType == 'Commercial & Other' ? 'other' 
        : params.row.assetType == 'External Opportunity' ? 'research_project'
        : params.row.assetType.replace(' ', '_').toLowerCase();
        
        store.commit('assetsModule/setTableServerOptions', {perPage: props.serverParams.perPage, page: props.serverParams.page})
        router.push({ name: 'TechnologyAssetDetail', params: {id: `${assetType}-${params.row.id}` }} )
    }
    
}

const onPageChange = (params: GoodTablePageChangeInterface) => emits('onPageChange', params);
const onOnPerPageChange = (params: GoodTablePerPageParamsInterface) => emits('onPerPageChange', params);

const selectedRows = ref<AssetSimpreInterface[]>([]);
const selectionChanged = (params: {selectedRows: TechnologyAssetAsTableRowInterface[]}) => selectedRows.value = params.selectedRows.map(({id, nickname, title}) => ({id, nickname, title}));
const compareAssets = () => {
    store.commit('assetsModule/setSelectedAssetForComparison', { assetsToCompare: selectedRows.value })
    store.commit('assetsModule/openComparisonCriteriaModal')
};

</script>