import gql from 'graphql-tag';

export const TDS_QUERY = gql`
    query getAssetTempTdses($id:Decimal) {
        assetTempTdses(filter:{
            and:[
                {asset:{id:{exact:$id}}}
                {processed:{exact:false}}
            ]
        }) {
            edges{
                node{
                    id
                    error
                    result{
                        properties{
                            quantitativeProperty
                            valueType
                            value1
                            value2
                            valueUnit
                            extraInformation
                            selected
                        }
                        certificates
                        applications
                    }
                }
            }
        }
    }`;

export const TDS_QUERY_WITH_DROPDOWNS = gql`
    query getAssetTempTdses($id:Decimal) {
        assetTempTdses(filter:{
            and:[
                {asset:{id:{exact:$id}}}
                {processed:{exact:false}}
            ]
        }) {
            edges{
                node{
                    id
                    error
                    result{
                        properties{
                            quantitativeProperty
                            valueType
                            value1
                            value2
                            valueUnit
                            extraInformation
                            selected
                        }
                        certificates
                        applications
                    }
                }
            }
        }
        valueTypes{
            edges{
                node{
                    id
                    name
                    symbol
                    description
                }
		    }
        }
    }`;
