<template>
    <div>
        <div v-if="pollingInProgress" class="list-tds  text-center p-2 one-five-green text-white select-none">
            working on tds extraction . . .
        </div>
        <div v-if="tempTdses && tempTdses.length>0" class="list-tds w-full text-center p-2 one-five-green text-white select-none">
            <div class="one-five-green">
                <p class=""><span class="mr-4">PROCESS TDS HERE</span>
                    <span v-for="tds in tempTdses" :key="tds.id" class="mr-2 p-2 pt-1 pb-1 bg-white text-black rounded cursor-pointer hover:shadow-md" @click="handleTdsClick(tds.id)">TDS {{tds.id}}</span>
                </p>
            </div>
        </div>
        <ModalSlots :show="isOpen" dialogClass="process-tds-modal" @close-modal="closeModal">
            <template v-slot:header><p>Process Extracted Information</p></template>
            <template v-slot:body>
                <LoaderPage v-if="processInProgress" loader-text="Submitting Form" gif-position="center"/>
                <AssetTdsProcessForm :value-types="valueTypes" />
            </template>
            <template v-slot:footer>
                <button class="button cancel" @click="closeModal(false)">cancel</button>
                <button class="button green-accept" @click="handleProcess">Process</button>
            </template>
        </ModalSlots>
    </div>
    
</template>
<script setup lang="ts">
import { defineProps,ref, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import ModalSlots from "@/components/ModalSlots.vue";
import LoaderPage from "./LoaderPage.vue";
import { useQuery } from '@vue/apollo-composable';
import {TDS_QUERY_WITH_DROPDOWNS} from "@/grapql/tdsQuery";
import AssetTdsProcessForm from "@/components/AssetTdsProcessForm.vue";
import {ASSET_TDS_PROCESS_FIELDS} from '@/models/assetTdsFormFields';
import {useAssetTdsToProcessFormData} from '@/composables/useAssetTdsToProcessFormData';

const store = useStore();

const emits = defineEmits(["onProcessed"]);

const props = defineProps(["assetId"]);
// hack, set and get id from store
store.commit("assetTdsModule/setAssetId",props.assetId);
const assetId = computed(()=> store.state.assetTdsModule.asset);

// set valuetypes for dropdown
const valueTypes = ref([])

const isOpen = ref(false);
const closeModal = (clean:boolean) => { 
    isOpen.value = false;
    if(clean===true){
        cleanUp();
    }
}
const openModal = () => {
    isOpen.value = true; 
}

const pollingInProgress = computed(()=> store.state.assetTdsModule.polling);
const processInProgress = computed(()=> store.state.assetTdsModule.processInProgress);

const getTdsesAndParse = () => {
    const {onResult, onError} = useQuery(TDS_QUERY_WITH_DROPDOWNS, {id: assetId});
    onError((error)=>{
        console.log(error);
    })
    onResult(async queryResult => {
        if (queryResult.data && queryResult?.data?.assetTempTdses) {
            store.commit('assetTdsModule/setTempTdsesValue', queryResult.data.assetTempTdses.edges.map(a=>a.node));
        }
        // set valuetypes for dropdown
        if(queryResult.data && queryResult?.data?.valueTypes){
            valueTypes.value = queryResult.data.valueTypes.edges.map(a=>(
                {key: a.node.name, value:a.node.name}
            ))
        }
    })
}

getTdsesAndParse();


const tempTdses = computed(()=>{
    return store.getters['assetTdsModule/getTempTdses'];
});

const tds = computed(()=> store.getters['assetTdsModule/getTempTdsForProcess']);


const handleTdsClick= (id)=>{
    // if tds is already set for process, just open the modal, else set tds for process
    if(tds.value){
        if(tds.value.id!=id){
            setTdsForProcess(id);
        }
    }
    
    openModal();
}

const setTdsForProcess=(id)=>{
    const filtered = tempTdses.value.filter(a=>a.id === id);
    const tempTds = filtered.length>0 ? filtered[0]:null;
    const output= useAssetTdsToProcessFormData(tempTds);
    const outputError = tempTds.error;
    store.commit('assetTdsModule/setTempTdsForProcess', {data:output, error:outputError});
}

const formSubmited = ref(false);

const handleProcess = async () =>{
    formSubmited.value = true;
    const mutation = ASSET_TDS_PROCESS_FIELDS.createMutation;
    await store.dispatch(mutation)
        .then(() => {
            formSubmited.value=false;
            closeModal(true);
            // emit onProcessed event
            emits('onProcessed');
        }).catch(()=>{
            console.log('error')
        });
}

async function cleanUp(){
    store.commit("assetTdsModule/cleanUpAfterProcess");
}

</script>