import gql from "graphql-tag";

export const BENCHLING_DESIGNS = gql`
  query getDesigns {
      prod_mart_data_test_designs {
        description
        total_grammage
        sealing_sides
        programmes
        pro_mat_name
        paper_content
        min_wvtr_38_90
        min_wvtr_23_85
        min_wvtr_23_50
        min_otr_38_90
        min_otr_23_50
        min_otr_23_0
        max_wvtr_38_90
        max_wvtr_23_85
        max_wvtr_23_50
        max_otr_38_90
        max_otr_23_50
        max_otr_23_0
        hs_max_tech_reps
        layers
        locked_design
      }

}`;

